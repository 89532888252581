import { Link } from "gatsby";
import * as React from "react";

// markup
const IndexPage = () => {
  // TODO remove debug date
  const today = new Date("2022-06-19");
  const compareToday = `${parseInt(today.getYear()) + 1900}-${parseInt(
    new Intl.DateTimeFormat("en-AU", {
      month: "numeric",
    }).format(today)
  ).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}-${today.getDate()}`;

  const currentDate = new Date(compareToday);
  const startDate = new Date("2022-06-19");
  const endDate = new Date("2022-07-05");

  const todayObject = {
    day: new Intl.DateTimeFormat("en-AU", { weekday: "long" }).format(today),
    date: today.getDate(),
    month: new Intl.DateTimeFormat("en-AU", { month: "numeric" }).format(today),
    monthLong: new Intl.DateTimeFormat("en-AU", { month: "long" }).format(
      today
    ),
  };
  const todayString = `${todayObject.date}/${todayObject.month} ${todayObject.day}`;
  const todaySlug =
    `${todayObject.date}-${todayObject.monthLong}-${todayObject.day}`.toLowerCase();

  if (currentDate >= startDate && currentDate <= endDate) {
    console.log(`Today: ${todayString}`);
  } else {
    console.log("Not in range");
  }

  const withinRange = !!(currentDate >= startDate && currentDate <= endDate);

  return (
    <>
      <section className="block">
        <ul className="big-link-list">
          <li>
            <Link to="/">🍙</Link>
          </li>
        </ul>
      </section>
      <section className="block absolute-bottom">
        <ul className="big-link-list">
          <li>
            <Link to="/seoul/">Seoul</Link>
          </li>
          {/* <li>
            <Link to="/jeju-do/">Jeju</Link>
          </li>
          <li>
            <Link to="/misc/">Misc</Link>
          </li> */}
        </ul>
      </section>
    </>
  );
};

export default IndexPage;
